<template>
  <b-card no-body data-type="custom">
    <!--begin: Wizard-->
    <div
      class="wizard wizard-2"
      id="platformInfoWizard"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <!--begin: Wizard Nav -->
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <div class="wizard-steps">
          <template v-for="(i, index) in wizardSteps">
            <div
              :key="index"
              class="wizard-step"
              data-wizard-type="step"
              :data-wizard-state="index < 1 ? 'current' : ''"
            >
              <WizardWrapper
                :icon="i.icon"
                :title="$t(`label.${i.title}`)"
                :description="$t(`label.${i.description}`)"
              ></WizardWrapper>
            </div>
          </template>
        </div>
      </div>
      <!--end: Wizard Nav -->

      <!--begin: Wizard Body -->
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Contents-->
        <b-row>
          <b-col offset-xl="2" xl="8">
            <template v-for="(i, index) in wizardSteps">
              <div
                :key="index"
                class="pb-5"
                data-wizard-type="step-content"
                :data-wizard-state="index < 1 ? 'current' : ''"
              >
                <component
                  v-if="constWizard.currentStep == i.step"
                  :is="i.component"
                  :platformInfo="platformInfoObj"
                ></component>
              </div>
            </template>
          </b-col>
        </b-row>
        <!--end: Wizard Contents-->
      </div>
      <!--end: Wizard Body -->
    </div>
    <!--end: Wizard-->
  </b-card>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import KTUtil from '@/assets/js/components/util';
  import KTWizard from '@/assets/js/components/wizard';
  import WizardWrapper from '@/views/components/wizard/WizardWrapper';
  import PlatformInfoContactUsAndHelp from '@/views/components/platform-info/PlatformInfoContactUsAndHelp';
  import PlatformInfoFAQ from '@/views/components/platform-info/PlatformInfoFAQ';
  import PlatformInfoTermsOfUse from '@/views/components/platform-info/PlatformInfoTermsOfUse';
  import PlatformInfoPrivacyPolicy from '@/views/components/platform-info/PlatformInfoPrivacyPolicy';
  import PlatformInfoAboutUs from '@/views/components/platform-info/PlatformInfoAboutUs';
  import PlatformInfoConsent from '@/views/components/platform-info/PlatformInfoConsent';
  import { i18nHelper } from '@/core/utils';
  import {
    PLATFORM_INFO_GET_PLATFORM_INFO,
    PLATFORM_INFO_INITIAL_GET_PLATFORM_INFO_STATE
  } from '@/core/store/platform-info.module';

  export default {
    name: 'PlatformInfo',
    mixins: [commonMixin],
    components: {
      WizardWrapper,
      PlatformInfoContactUsAndHelp,
      PlatformInfoFAQ,
      PlatformInfoTermsOfUse,
      PlatformInfoPrivacyPolicy,
      PlatformInfoAboutUs,
      PlatformInfoConsent
    },
    data: () => ({
      constWizard: {
        currentStep: 1
      },
      wizardSteps: [
        {
          icon: 'far fa-address-card',
          title: 'contactUsAndHelp',
          description: 'platformContactInfo',
          component: 'PlatformInfoContactUsAndHelp',
          step: 1
        },
        {
          icon: 'far fa-question-circle',
          title: 'faq',
          description: 'frequentlyAskedQuestions',
          component: 'PlatformInfoFAQ',
          step: 2
        },
        {
          icon: 'fas fa-pen-nib',
          title: 'termsOfUse',
          description: 'userRestrictions',
          component: 'PlatformInfoTermsOfUse',
          step: 3
        },
        {
          icon: 'fas fa-user-lock',
          title: 'privacyPolicy',
          description: 'useAndProtectTheUserInformation',
          component: 'PlatformInfoPrivacyPolicy',
          step: 4
        },
        {
          icon: 'fas fa-info',
          title: 'aboutUs',
          description: 'detailedIntroductionAboutTheBackgroundOfThePlatform',
          component: 'PlatformInfoAboutUs',
          step: 5
        },
        {
          icon: 'fas fa-handshake',
          title: 'guardianConsent',
          description: 'rightsAndDutiesAGuardianHasInRespectOfAChild',
          component: 'PlatformInfoConsent',
          step: 6
        }
      ],
      platformInfoObj: null
    }),
    computed: {
      platformInfoComplete() {
        return this.$store.state.platformInfo.platformInfo.complete;
      }
    },
    watch: {
      platformInfoComplete() {
        let response = this.$store.state.platformInfo.platformInfo;
        let title = i18nHelper.getMessage('label.getPlatformInfo');
        let initialStateAction = PLATFORM_INFO_INITIAL_GET_PLATFORM_INFO_STATE;
        let successAction = (response) => {
          this.importPlatformInfoObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialPlatformInfo();
    },
    mounted() {
      this.initWizard();
    },
    methods: {
      importPlatformInfoObj(response) {
        this.platformInfoObj = response.data;
      },
      initWizard() {
        // Initialize form wizard
        let vm = this;

        const wizard = new KTWizard('platformInfoWizard', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        });

        vm.constWizard = wizard;

        // Change event
        wizard.on('change', function (/*wizardObj*/) {
          KTUtil.scrollTop();
        });
      },
      getPlatformInfo() {
        this.$store.dispatch(PLATFORM_INFO_GET_PLATFORM_INFO);
      },
      initialPlatformInfo() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.platformInfo')
            }
          ]
        };

        this.getPlatformInfo();
        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/pages/wizard/wizard-2.scss';
</style>
